<template>
  <v-card max-width="1200" class="mx-auto elevation-1" min-height="100vh">
    
    <v-layout>                
      <AppBar />
      <v-main>
        <v-container class="py-12">
          <!-- Hero Banner -->
          <v-sheet class="hero-banner rounded-xl d-flex align-center justify-between pa-8 mb-10">
            <div>
              <h4 class="text-h4 font-weight-bold mb-2">Servers</h4>
              <p class="text-body-1 mb-4">
                Here you can monitor and manage your servers.
              </p>           
            </div>
          </v-sheet>
          
          <div v-if="this.user.servers.length > 0">
            <v-row dense>
            <!-- Account Status -->
            <v-col cols="12" md="12" v-for="(item) in this.user.servers" :key="item.id">
              <v-card class="status-card rounded-lg pa-6 elevation-2 d-flex flex-column justify-center" style="min-height:200px;">
                <div class="d-flex align-center mb-3">
                  <v-icon size="36" color="green">mdi-jellyfish-outline</v-icon>
                  <h3 class="text-h6 font-weight-bold ml-3">{{item.name}}</h3>
                </div>
                <p class="text-body-2">Address: <strong>{{ item.URL }}</strong></p>
                <p class="text-body-2 mt-1">Username: <strong>{{ item.username }}</strong></p>
                
                <p class="text-body-2 mt-2">Status: <v-chip color="green"  v-if="item.status==1">Running</v-chip>                     
                <v-chip color="red" v-else-if="item.status==0">Down</v-chip>  
                <v-chip color="yellow" v-else >Unknown</v-chip>
                <span class="ml-4">Performance: </span>  
                <v-chip color="green" v-if="item.load==1" >Normal</v-chip> 
                <v-chip color="teal" v-else-if="item.load==0">Excellent</v-chip>                 
                <v-chip color="red" v-else-if="item.load==2">Average</v-chip>
                <span class="ml-4">Account Status: </span> 
               
                  <v-chip color="red" v-if="item.acc_status=='Disabled'"> {{ item.acc_status }} </v-chip>
                  <v-chip color="green" v-else>  {{ item.acc_status }} </v-chip>

              </p>
              </v-card>
            </v-col>
            </v-row>              
          <v-container>
            <v-card variant="flat" style="background-color: transparent;">              
              <v-btn color= "secondary" class="float-right" v-on:click="RestorePassword(this.user.servers[0].id)" :disabled="!doneRestorePwd">Restore Password</v-btn>
              <v-btn color= "primary" class="float-right mr-4" v-on:click="Launch(this.user.servers[0].URL)">Launch</v-btn>  
            </v-card>
            
            
          </v-container>
          
          </div>
          <div v-else>
            <v-alert color="warning" type="warning" text="It seems like you have not been assigned to any server yet. You can request access here."> 
              <v-btn color= "primary" class="float-right mr-1" v-on:click="RequestAccess()" :disabled="!doneRequest">Request Access</v-btn>  
            </v-alert>
          </div>

        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>

<style scoped>
.hero-banner {
background: linear-gradient(to right, #4a90e2, #13dbfe);
color: white;
}

.status-card {
background: #f3f7fa;
}

.news-card {
background: #e8f5ff;
}

.updates-card {
background: #ffffff;
}

.v-btn {
text-transform: none;
font-size: 16px;
}

.v-card:hover {

}
</style>

<script>
import { useUserStore } from '../stores/user'
import AppBar from '../components/AppBar.vue'
import axios from "axios";
export default {
  data: () => ({
      status: "Unknown",     
      doneRestorePwd:true,
      doneRequest: true,  
      subscription: {id:0,
                    last_payment:"",
                  user_id: 0},      
    }),
  setup() {
    const user = useUserStore();    
    return{user}
   
  }, 
  computed: {
  
  isExpired: function()
  {
    return Date.parse(this.user.expiry_date)-Date.parse(new Date())<0 ? true: false;
  },
},
  mounted(){
    const user = useUserStore();  
    this.user = user;    
    
   let self = this;
    for (const item of this.user.servers)
    {      
      self.StatusCheck(item.URL,item.id,user.id);
    }
    
  },
  methods:{

    HasAccess()
    {
      var date = new Date(this.user.last_payment);
      var now = new Date();
      now.setHours(0,0,0,0);      
      if (date < now) {
        
        return false;
      } else {
        
        return true;
      }        
    },
    RequestAccess()
    {
      this.doneRequest = false;
      if(!this.isExpired)
      {
        const { id, token, email} = this.user; // Extract only needed fields
        
        let Data = {                    
                ID:this.user.id,
                Email:this.user.email,
                user: { id, email, token },
            }                                    

            axios.post("https://mediatrunk.net/mobilevids/request_access.php",Data)
            .then(response => {              
              alert(response.data.reason);
              if(response.data.status == 1)
              {
                this.user.servers[0] = response.data.server;                
                for (const item of this.user.servers)
                {                
                  this.StatusCheck(item.URL,item.id);
                }
              }
              
              
              this.doneRequest = true;
            })
            .catch(error => {
                this.errorMessage = error.message;
                this.doneRequest = true;
                console.error("There was an error!", error);
            });      
      }else
      {
        alert("You account has expired, please renew your billing information");
      }
        
    },
    Launch(URL){
      window.open(URL,'_blank');                    
    },
    RestorePassword(ServerId)
    {
      let self = this;
      self.doneRestorePwd = false;
      const { id, token, email} = this.user; // Extract only needed fields
      
      let RecoverData = {
        email:this.user.email,
        server_id:ServerId,
        user: { id, email, token },
            }                        
            axios.post("https://mediatrunk.net/mobilevids/cron/recover_password.php", RecoverData)
            .then(response => {
                        
                alert(response.data.msg);               
                self.doneRestorePwd = true;
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                alert(error); 
                self.doneRestorePwd = true;
            });
    },
    StatusCheck(server,server_id,user_id)
        {              
          const { id, token, email} = this.user; // Extract only needed fields
          let self = this;
          let Data = {
                Server:server,      
                id:server_id,
                userId: user_id,
                user: { id, email, token },      
            }                                    

            axios.post("https://mediatrunk.net/mobilevids/ping.php",Data)
            .then(response => {
              
              for (const item of self.user.servers)
              {                
                if(item.id == server_id)
                {
                  
                  item.load = response.data.load;                              
                  item.status = -1;
                  item.username = response.data.username;
                  item.acc_status = response.data.acc_status;
                  if(response.data.status == 0)
                    item.status = 0;
                  else                
                    item.status = 1;    
                    
                }                                
              }
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
        }
  },
  components: {
    AppBar
    },

}
</script>