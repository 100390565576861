<template>
    <div class="mt-10">  
      <v-card >
        <div class="text-subtitle-1 text-medium-emphasis">Account</div>
  
        <v-text-field density="compact" placeholder="Email address" prepend-inner-icon="mdi-email-outline" v-model="email"
          variant="outlined"></v-text-field>
  
        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          Password
  
          <v-btn v-on:click="recoverPassword()" variant="flat" class="text-caption text-decoration-none text-blue">
            Forgot login password?</v-btn>
        </div>
  
        <v-text-field :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
          density="compact" placeholder="Enter your password" prepend-inner-icon="mdi-lock-outline" variant="outlined"
          @click:append-inner="visible = !visible" v-model="password" 
          @keydown.enter="tryLogin"></v-text-field>
  
        <v-btn block class="mb-8" color="blue" size="large" variant="tonal" v-on:click="tryLogin()">
          Log In
        </v-btn>
  
        <v-card-text class="text-center">
            <v-btn size="x-small" variant="text" v-on:click="this.$emit('changeTabEvent', 'two')">
                Sign up now <v-icon icon="mdi-chevron-right"></v-icon>
            </v-btn>

        </v-card-text>
      </v-card>
    </div>
  </template>
  <script>
  import axios from "axios";
  import { useUserStore } from '../stores/user'
  import router from '../router'
  export default {
    

    data: () => ({
      visible: false,
      email: "",
      password: ""
    }),
    methods:{
        tryLogin()
        {
            let LoginData = {
                Email:this.email,
                Password:this.password
            }                        
            axios.post("https://mediatrunk.net/mobilevids/login.php", LoginData)
            .then(response => {
                console.log(response);
                if(response.data.status == 0)
                alert(response.data.reason);
                else
                {
                    console.log(response.data);
                    const user = useUserStore();
                    user.$patch({email: response.data.mail,
                                 name: response.data.name,
                                 surname: response.data.surname,
                                 phone:response.data.phone,
                                 country:response.data.country,
                                 city:response.data.city,
                                 address: response.data.address,
                                 province: response.data.province,
                                 postal:response.data.postal,
                                 last_payment:  response.data.last_payment,
                                 expiry_date: response.data.expiry_date,
                                 id:response.data.id,                                 
                                 billing_id: response.data.billing_id,
                                 cost: response.data.cost,
                                 role: response.data.role,
                                 token: response.data.auth_token,
                                 invites_count: response.data.invites_count,
                                 servers:response.data.servers});                    
                    router.push('/')
                }
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
        },
        recoverPassword()
        {
            let RecoverData = {
                Email:this.email,                
            }                        
            axios.post("https://mediatrunk.net/mobilevids/recover.php", RecoverData)
            .then(response => {
                console.log(response);
                alert(response.data.reason);
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
        }

    }
  }
  </script>