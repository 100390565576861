<template>
  <v-card max-width="1200" class="mx-auto elevation-1" min-height="100vh">
    
    <v-layout>                
      <AppBar />
      <v-main>
        <v-container fluid>
       <!-- Hero Banner -->
       <v-sheet class="hero-banner rounded-xl d-flex align-center justify-between pa-8 mb-10">
            <div>
              <h4 class="text-h4 font-weight-bold mb-2">Billing</h4>
              <p class="text-body-1 mb-4">
                Manage your billing information here and check status. 
              </p>                                       
            </div>
          </v-sheet>
          
      <div class="text-subtitle-1 text-medium-emphasis"></div>
        


        <div id="payment-form">
          <v-row  dense>
                    
            <v-col cols="12" md="6">
                
                <v-card class="status-card rounded-lg pa-6 elevation-2 d-flex flex-column justify-center" style="min-height:200px;" :disabled="isSubscribe">
                  
                   <v-row  v-for="plan in plans" :key="plan.id">                
                  <v-btn  class="me-4 mt-5" color="indigo-darken-3" @click="OpenUrl(plan)">{{ plan.pricing_label }} {{  user.cost}}</v-btn>
                    
                  </v-row> 
                  
                  <p class="mt-5">Will open a popup, do not close the popup until the transaction is done.</p>                  
                  <p class="mt-2"><b>Status:</b> {{pay_status}}</p>
                  
                </v-card>
              </v-col>

              <v-col cols="12" md="6">
                
                  <v-card   color="blue-grey-darken-4" class="status-card rounded-lg pa-6 elevation-2 d-flex flex-column justify-center" style="min-height:200px;">
                  <h3><b>Subscription status</b></h3>
                  <p v-if="!isSubscribe">No active subscription</p>
                  <div v-if="isSubscribe">
                    <p>Active</p>
                    <p><b>Renewed:</b> {{ subscription.last_payment }}</p>
                    <v-btn  class="me-4 mt-5" color="red-accent-4" @click="confirmCancel = true">Cancel</v-btn>
                  </div>
                  <h3><b>Account status</b></h3>
                  <div >
                    <p>Status: {{ isExpired ? "Expired": "Active"  }}</p>                                     
                    <p v-if="!isExpired">Expiry Date: <b> {{ expiryDate }}</b></p>                       
                  </div>                   
                  </v-card>                                             
            </v-col>
        </v-row>   
          
         
        </div>
          
          
        </v-container>

        <v-dialog max-width="500" v-model="confirmCancel">
          <v-card title="Are you sure?">
            <v-card-text>
              This will permanently cancel your subscription, your account will still function until {{ subscription.next_renewal }} and after that you will need to renew your subscription. 
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                text="I changed my mind"
                @click="confirmCancel = false"
              ></v-btn>
              <v-btn
                text="Yes, I am sure" color="red-accent-4"
                @click="CancelSub()"
              ></v-btn>
            </v-card-actions>
          </v-card>
     
      </v-dialog>

      </v-main>
    </v-layout>
  </v-card>
</template>
<style scoped>
.hero-banner {
background: linear-gradient(to right, #4a90e2, #13dbfe);
color: white;
}

.status-card {
background: #f3f7fa;
}

.news-card {
background: #e8f5ff;
}

.updates-card {
background: #ffffff;
}

.v-btn {
text-transform: none;
font-size: 16px;
}

.v-card:hover {

}
</style>
<script>

import AppBar from '../components/AppBar.vue'
import { useUserStore } from '../stores/user'
import axios from "axios";

export default {
data: () => ({
      user: undefined,
      confirmCancel: false,
      subscription: {id:0,
                    last_payment:"",
                  user_id: 0},
      orderId: "",
      plans: [],
      order_timer: 0,
      name: "",
      surname: "",
      phone: "",
      address: "",
      city: "",
      postal: "",
      country: "",
      province: "",
      popup: undefined,
      countries:[],
      pay_status: "Waiting",
      payment_successful: false,
      
  }),
 
computed: {
  isSubscribe:function () {
    return this.subscription.id>0 && !this.isExpired ? true: false;
  },
  isExpired: function()
  {
    return Date.parse(this.user.expiry_date)-Date.parse(new Date())<0 ? true: false;
  },
  expiryDate: function()
  {
    return this.user.expiry_date; 
  }

  
  
},
components: {
  AppBar
  },
  created(){
    this.user = useUserStore(); 
    console.log(this.user);
    this.countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Brazil", code: "BR" },
  { name: "Brunei", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cabo Verde", code: "CV" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Costa Rica", code: "CR" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czechia", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Eswatini", code: "SZ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Greece", code: "GR" },
  { name: "Grenada", code: "GD" },
  { name: "Guatemala", code: "GT" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Honduras", code: "HN" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, North", code: "KP" },
  { name: "Korea, South", code: "KR" },
  { name: "Kosovo", code: "XK" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Laos", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia", code: "FM" },
  { name: "Moldova", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "North Macedonia", code: "MK" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Qatar", code: "QA" },
  { name: "Romania", code: "RO" },
  { name: "Russia", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syria", code: "SY" },
  { name: "Taiwan", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Vatican City", code: "VA" },
  { name: "Venezuela", code: "VE" },
  { name: "Vietnam", code: "VN" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" }
];
    this.name = this.user.name;
    this.surname = this.user.surname;
    this.city = this.user.city;
    this.address = this.user.address;
    this.province = this.user.province;
    this.postal = this.user.postal;
    this.phone = this.user.phone;
    this.country = this.user.country;    
    this.orderId = this.UID();        

    this.getPlans();

  },
  mounted(){
 
    this.getSubscription();
    this.UpdateUser();
  },

  methods:{  
    WatchOrderTimer: function(){  
      let self = this;
      const { id, token, email} = this.user; // Extract only needed fields
     

      this.order_timer = setInterval(function(){        
      let data = {
        order_id: self.orderId,
        user: { id, email, token },  
      }
      axios.post("https://mediatrunk.net/mobilevids/get_order.php",data)
              .then(response => {
                if(response.data.status==1)
                {
                  //console.log(response.data.order); 
                  self.pay_status = response.data.order.status; 
                  
                  let json = JSON.parse(response.data.order.reason);
                  if(json!=null)
                  {
                    self.UpdateUser();
                
                    self.getSubscription();
                    clearInterval(self.order_timer);
                  }
                  
                  

                }
                                        
      
              })
              .catch(error => {      
                  console.error("There was an error!", error);   
                    });          
    }, 3000);
    },
    CancelSub(){
      const { id, token, email} = this.user; // Extract only needed fields
      let self = this;
      let data = {
        user: { id, email, token },
        subscription: this.subscription,
      }
      axios.post("https://mediatrunk.net/mobilevids/cancel_sub.php",data)
              .then(response => {
                console.log(response.data);
                if(response.data.status == 1)
                {
                  self.confirmCancel = false;
                  alert(response.data.reason);                 
                 self.getSubscription();
                }
      
              })
              .catch(error => {
                self.errorMessage = error.message;
                  console.error("There was an error!", error);
                  alert(error);
                  self.confirmCancel = false;
                  self.getSubscription();
                    });
    },
    getSubscription()
    {
      const { id, token, email} = this.user; // Extract only needed fields
      

      let data = {
        user_id: this.user.id,
        user: { id, email, token },
      }
      this.$axios
        .post('/mobilevids/get_subscription.php', data)
        .then((response) => {
          if(response.data.status == 1)
                {
                  this.subscription = response.data.subscription;
                }else
                this.subscription =  {id:0,
                                      last_payment:"",
                                      user_id: 0}
                                      ;
        })
        .catch((error) => {
          // Handle errors
          if (error.response) {
            this.error = `Error ${error.response.status}: ${error.response.data.message}`;
          } else {
            this.error = error.message;
          }
        })
        .finally(() => {
          // Always executed after success or error
          
        });

    },

    getPlans(){
      const { id, token, email} = this.user; // Extract only needed fields
      let data = {
              user: { id, email, token },
        }
        axios.post("https://mediatrunk.net/mobilevids/get_plans.php",data)
              .then(response => {
                console.log(response.data);
                this.plans = response.data.data;
                
              })
              .catch(error => {
                  this.errorMessage = error.message;
                  console.error("There was an error!", error);
                  alert(error);
                  
                    });
    },
    UID(){
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    },
    OpenUrl(plan){
      

      const url = 'https://mediatrunk.net/gateway/payment.php?sandbox=0&user_id='+this.user.id+'&mode=1&ul_id='+plan.id+'&uuid='+ this.orderId;
      const windowFeatures = 'width=900,height=700,left=100,top=100,menubar=no,toolbar=no,location=no,status=no,scrollbars=yes';

      // Open the popup window with the defined size and features
      window.open(url, 'popupWindow', windowFeatures);
      this.pay_status = "Waiting for transaction to finish";
      this.WatchOrderTimer();

    },  
    VerifyData()
    {
      if( this.name=="" || this.surname =="" || this.phone == "" ||
          this.user.email== "" || this.address== "" || this.city == "" || 
          this.postal== "" || this.country == "" || this.province == "")
          return false;
          else return true;
    },
    UpdateUser()
        {
          const { id, token, email} = this.user; // Extract only needed fields
          
            let data = {
              user_id: this.user.id,
              user: { id, email, token },
            }                        
            axios.post("https://mediatrunk.net/mobilevids/get_user.php", data)
            .then(response => {
                
                if(response.data.status == 0)
                alert(response.data.reason);
                else
                {                    
                    const user = useUserStore();
                    user.$patch({email: response.data.mail,
                                 name: response.data.name,
                                 surname: response.data.surname,
                                 phone:response.data.phone,
                                 country:response.data.country,
                                 city:response.data.city,
                                 address: response.data.address,
                                 province: response.data.province,
                                 postal:response.data.postal,
                                 last_payment:  response.data.last_payment,
                                 expiry_date: response.data.expiry_date,
                                 id:response.data.id,                                 
                                 billing_id: response.data.billing_id,                                 
                                 role: response.data.role,                                 
                                 invites_count: response.data.invites_count,
                                 servers:response.data.servers});                    
                    
                }
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
        }         
            
  }

}
</script>