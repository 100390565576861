<template>
    <v-card max-width="1200" class="mx-auto elevation-1" min-height="100vh">
      <v-layout>                
        <AppBar />
        <v-main>
          <v-container fluid>
                <!-- Hero Banner -->
          <v-sheet class="hero-banner rounded-xl d-flex align-center justify-between pa-8 mb-10">
            <div>
              <h4 class="text-h4 font-weight-bold mb-2">Account</h4>
              <p class="text-body-1 mb-4">
                Configure you account settings.
              </p>                                       
            </div>
          </v-sheet>
        <v-card class="status-card rounded-lg pa-6 elevation-2 d-flex flex-column justify-center" style="min-height:200px;">   
          <div class="text-subtitle-1 text-medium-emphasis">Email</div>  
          <v-text-field density="compact" placeholder="Email address" prepend-inner-icon="mdi-email-outline" v-model="user.email"
            variant="outlined" readonly=""></v-text-field>
          <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
            Old Password          
          </div>
    
          <v-text-field :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
            density="compact" placeholder="Enter your old password" prepend-inner-icon="mdi-lock-outline" variant="outlined"
            @click:append-inner="visible = !visible" v-model="password"></v-text-field>
            <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
            New Password          
          </div>
            <v-text-field :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
            density="compact" placeholder="Enter your new password" prepend-inner-icon="mdi-lock-outline" variant="outlined"
            @click:append-inner="visible = !visible" v-model="new_password"></v-text-field>
    
          <v-btn block class="mb-8" color="blue" size="large" variant="tonal" v-on:click="tryChangePassword()">
            Change Password
          </v-btn>
        </v-card>
  
 
                                            
          </v-container>
        </v-main>
      </v-layout>
    </v-card>
  </template>
<style scoped>
.hero-banner {
background: linear-gradient(to right, #4a90e2, #13dbfe);
color: white;
}

.status-card {
background: #f3f7fa;
}

.news-card {
background: #e8f5ff;
}

.updates-card {
background: #ffffff;
}

.v-btn {
text-transform: none;
font-size: 16px;
}

.v-card:hover {

}
</style>
<script>
import { useUserStore } from '../stores/user'
import AppBar from '../components/AppBar.vue'
import axios from "axios";

export default {
  data: () => ({
      visible: false,
      password: "",
      new_password: ""
    }),
  setup() {
    const user = useUserStore();        
    return{user}
  },  
  components: {
    AppBar
    },
    mounted(){
      
    },
    methods:{
    Launch(URL){
      window.open(URL,'_blank');                    
    },
    tryChangePassword()
        {
          const { id, token, email} = this.user; // Extract only needed fields          
            let LoginData = {
                Email:this.user.email,
                Password:this.password,
                NewPassword: this.new_password,
                user: { id, email, token },
            }                        
            axios.post("https://mediatrunk.net/mobilevids/change_pw.php", LoginData)
            .then(response => {
                
              alert(response.data.reason);                
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
        }
  }

}
</script>