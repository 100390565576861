<template>
    
      <v-layout>       
          <AppBar />
          <v-main>                      
            <v-container>   
               <!-- Hero Banner -->
               <v-sheet class="hero-banner pa-6 mb-2">
                <v-row align="center" justify="space-between">
                  <!-- Title Section -->
                  <v-col cols="12" md="12">
                    <h4 class="text-h4 font-weight-bold">Live TV Player</h4>                  
                  </v-col>
                 
                  
                </v-row>
              </v-sheet>




              <v-card class="mx-auto " color="grey-lighten-4" v-if="user_valid" style="margin-top: -8px;">                        
                  <v-container style="background-color: black; color: white;" class="pa-0">                                                
                      <v-row class="pa-2">
                        <v-col cols="12" md="8">
                          <div align="center" class="liveTvWrapper">
                            <iframe align="center" allow="autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; encrypted-media"
                            allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" id="livetvplayer" :src="'https://mvids.org/live/stream.php?id=' +current_channel_index"
                            class="liveplayer"
                            >    
                            </iframe>           
                          </div>  
                        </v-col>  
                        <v-col cols="12" md="4" class="searchCol">                          
                           <!-- Search Bar Section -->                  
                          <v-text-field
                            v-model="search"                            
                            class="searchBar"
                            label="Search"
                            placeholder="Channel name"
                            prepend-inner-icon="mdi-magnify"
                            variant="plain"
                            hide-details
                            single-line
                            
                          
                          ></v-text-field>
                          <v-data-table                              
                              calculate-widths="false"
                              :headers="headers"
                              :items="channels"
                              :search="search"      
                              item-key="id"
                            >
                            
                              <!-- Custom header slot -->
                              <template v-slot:headers="">
                                <tr>
                                  <th style="width: 10px;"></th>
                                  <th
                                    v-for="header in headers"
                                    :key="header.value"
                                    :style="{
                                      width: header.width,
                                      color: 'black',
                                      textAlign: 'center',
                                      padding: '10px',
                                      fontWeight: 'bold',
                                      textAlign: 'left',
                                    }"
                                  >
                                  {{ header.title }}
                                  </th>
                                </tr>
                              </template>

                              <!-- Custom item rows -->
                              <template v-slot:body="{ items }">

                                <tr
                                  v-for="item in items"
                                  :key="item.id"
                                  :class="selectedRow === item.id ? 'selectedRow': ''"
                                  @click="play_item(item)"
                                >
                                  <td style="max-width: 10px;">
                                    <v-icon v-if="selectedRow === item.id">mdi-play-outline</v-icon>
                                  </td>        
                                  <td
                                    v-for="header in headers"
                                    :key="header.value"
                                    style="margin-left: -10px;"
                                  >
                                    {{ item[header.value] }}
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                        </v-col>
                  
                      </v-row>
                         
                           
                  </v-container>
              <v-card>
         
               
            





              </v-card>
                </v-card> 
                <v-card class="mx-auto " color="grey-lighten-4" v-else>    
                  <h4 align="center" style="padding:15px;">This section is only accessible to members with active subscription</h4>
                </v-card>
 
            </v-container>
        </v-main>
        
    </v-layout>
 
  </template>
  <style scoped>
    .liveTvWrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
        
    }
    .liveTvWrapper iframe {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        border-width: 0px;
    }
    .searchCol{
      
      
      background: linear-gradient(25deg, #8e63d3, #a11095, #24C3F5, #24C3F5, #24C3F5);
    }
  .searchBar{
    padding: 10px;
    border-width: 0px!important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    max-width: 100%;
    background-color: white;
    color:black;
    
  }
  .hero-banner {
  background: linear-gradient(to right, #4a90e2, #13dbfe);
  color: white;
  

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  }
  .selectedRow {
  color: white;
  font-weight: bold;
  background: linear-gradient(135deg, #693bbe, #a11095, #77685f, #13cfc0, #2416e6);
  background-size: 400% 400%; /* Make the gradient larger for a dramatic effect */
  animation: waveEffect 10s ease-in-out infinite; /* Faster animation */
}

@keyframes waveEffect {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%; /* Move gradient fully across horizontally */
  }
  100% {
    background-position: 0% 50%; /* Reset to starting position */
  }
}
  .status-card {
  background: #f3f7fa;
  }
  
  .news-card {
  background: #e8f5ff;
  }
  
  .updates-card {
  background: #ffffff;
  }
  
  .v-btn {
  text-transform: none;
  font-size: 16px;
  }
  
  .v-card:hover {
  
  }
  </style>
<script>
import axios  from 'axios';
import AppBar from '../components/AppBar.vue'
import {useUserStore } from '../stores/user'
export default {
  name: 'LiveTV',

  components: {  
    AppBar,  
  },

  data: () => ({
        search:"",
        selectedRow: null,
        user_valid: true,
        headers: [
                  
                  {title: '#', value: 'id', width: "10px"},
                  { title: 'Channel', value: 'channel', width:"100%" },                  
                 ],
        channels: [],
        current_channel:"",
        current_channel_index:1,
  }),
  mounted() {
    
    
    const user = useUserStore();        
    console.log(user);
    var now = new Date();
    var exp = new Date(user.expiry_date);

    now.setHours(0,0,0,0);
    
    if (exp < now) {
      this.user_valid = false;
    } else {
      this.user_valid = true;
    }     
    
    this.user_valid = true; // temp
    const { id, token, email} = user; // Extract only needed fields
    let data = {
      user: { id, email, token },
      };
    axios.post("https://mvids.org/live/get_channels.php",data)
            .then(response => {
                
                if(response.data.status == 0)
                alert(response.data.reason);
                else
                {
                    console.log(response.data.channels);
                    this.channels = response.data.channels;
                    this.current_channel = this.channels[0].channel;
                    this.selectedRow = this.channels[0].id;
                }
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
 
    },
    methods: {
      toDay()
      {
        let today = new Date().toISOString().slice(0, 10)
        return today;
      },
      nextChannel()
      {
        this.current_channel_index += 1;     
        
        let item = this.channels[this.current_channel_index-1];   
        this.play_item(item,this.current_channel_index);
      },
      prevChannel()
      {
        this.current_channel_index -= 1;     
        let item = this.channels[this.current_channel_index-1];   
        
        this.play_item(item,this.current_channel_index);
      },
      play_item(item)
      {                 
          this.current_channel = item.channel;
          this.current_channel_index = item.id;
          this.selectedRow = item.id; // Update selected row ID
          window.scrollTo(0,0);
      },
    }
  
}
</script>