// router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import MainPage from './views/MainPage.vue'
import BillingPage from './views/BillingPage.vue'
import LoginPage from './views/LoginPage.vue'
import ServerPage from './views/ServerPage.vue'
import { useUserStore } from './stores/user'
import AccountPage from './views/AccountPage.vue'
import InvitePage from './views/InvitePage.vue'

import AdminPage from './views/admin/AdminPage.vue'
import UserPage from './views/admin/UserPage.vue'
import SquareCustomersPage from './views/admin/SquareCustomers.vue'
import SquareTransactionsPage from './views/admin/SquareTransactions.vue'
import OrdersPage from './views/admin/OrdersPage.vue'
import ServersPage from './views/admin/ServersPage.vue'
import LiveTV from './views/LiveTV.vue'

const routes = [
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/',
        name: 'MainPage',
        component: MainPage
    },
    {
        path: '/server',
        name: 'ServerPage',
        component: ServerPage
    },
    {
        path: '/billing',
        name: 'BillingPage',
        component: BillingPage
    },
    {
        path: '/live_tv',
        name: 'LiveTV',
        component: LiveTV
        
    },
    {
        path: '/account',
        name: 'AccountPage',
        component: AccountPage
    },
    {
        path: '/invite',
        name: 'InvitePage',
        component: InvitePage
    },
    

    // Admin VIEWS
    {
        path: '/admin',
        name: 'AdminPage',
        component: AdminPage
    },
    {
        path: '/a_servers',
        name: 'ServersPage',
        component: ServersPage
    },
    
    {
        path: '/a_users',
        name: 'Admin-Users',
        component: UserPage
    },
    {
        path: '/a_square',
        name: 'Square-Customers',
        component: SquareCustomersPage
    },  {
        path: '/a_square_transactions',
        name: 'Square-Transactions',
        component: SquareTransactionsPage
    },
    {
        path: '/a_orders',
        name: 'Orders-Page',
        component: OrdersPage
    },
    
    
    

]

const router = createRouter({ history: createWebHistory(), routes })
router.beforeEach((to, from, next) => {
    const user = useUserStore();
    
    if(to.path!= "/login")  
    {                     
         if (user.email == null || user.email == '') {
            next({
            path: "/login",
            });
        }else next();

    } else next();      
    });

export default router