<template>
    <v-card max-width="1200" class="mx-auto "  >     

      <v-layout>                
        <AppBar_Admin />
        <v-main>
          <v-container fluid>          
            <v-data-table
                :search="search"
                v-model:expanded="expanded"
                :headers="this.transactionsHeaders"
                :items="this.transactions"
                item-value="id"
                single-expand
                show-expand
                @update:expanded="loadDetails"
            >
            
                <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Transactions</v-toolbar-title>
                    <v-text-field
                    v-model="search"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                    ></v-text-field>
                </v-toolbar>
                
                </template>
                <template v-slot:expanded-row="{ columns, item }">
                <tr>
                    <td :colspan="columns.length">
                    <v-card color="surface-variant" class="mt-5 mb-5">
                        <template v-slot:title>
                          Transaction Id: {{ item.id }}                  
                        </template>

                        <template v-slot:subtitle>
                         Card Info                                                        
                        </template>

                        <template v-slot:text>
                        <p>Status: {{ item.card_details.status }}</p>
                        <p>Brand: {{ item.card_details.card.card_brand }}</p>
                        <p>Last 4: {{ item.card_details.card.last_4 }}</p>
                        <p>Exp Month: {{ item.card_details.card.exp_month }}</p>
                        <p>Exp Year: {{ item.card_details.card.exp_year }}</p>
                        </template>
                        <template v-slot:actions>                                                 
                        </template>
                    </v-card>                  
                    </td>
                </tr>
                </template>
            </v-data-table>   

            
          </v-container>
        </v-main>
      </v-layout>
    </v-card>
  </template>

<script>
import { useUserStore } from '../../stores/user'
import AppBar_Admin from '../../components/AppBar_Admin.vue'
import axios from "axios";


export default {
  data: () => ({
      visible: false,
      dialog: false,
      dialog_server: false,
      sel_transaction: null,
      search: '',
      expanded: [],
      transactions: [],
      transactionsHeaders: [
              
          { title: 'Status', key: 'status' },
          { title: 'Amount', key: "amount_money.amount"},
          { title: 'Customer Id	', key: 'customer_id' },          
          {title: 'Created At', key: 'created_at'},
          {title: 'Updated At', key: 'updated_at'},
                    
          { title: '', key: 'data-table-expand' },
        ],
 
    }),
  setup() {
    const user = useUserStore();        
    
    return{user}

  },  
  computed: {
  
  },
  components: {
    AppBar_Admin
    },
    mounted(){
        this.getTransactions();
 
    },
    methods:{
    
    FormatDateFromISOString(isoString) {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
  
    loadDetails(item)
    {      

      console.log(item);
      this.expanded.forEach((ex) => {
        if(ex == item)
        {
          this.transactions.forEach((u) => {
          if(u.id == item)
          {
            this.sel_transaction = u;
                 
          }
        });
        }
      });
      
      
    },        
    Launch(URL){
      window.open(URL,'_blank');                    
    },
    getTransactions()
    {     const { id, token, email} = this.user; // Extract only needed fields    
    let data = {user: { id, email, token },  }
                 
        axios.post("https://mediatrunk.net/mobilevids/admin/square_transactions.php",data)
        .then(response => {
            this.transactions = response.data.transactions;     
            console.log(this.transactions);
            
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });
    },
  }

}
</script>