import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { loadFonts } from './plugins/webfontloader'
import { createPinia } from 'pinia'
import axiosPlugin from './plugins/axios';

loadFonts();

const app = createApp(App);
export const pina = createPinia();
  app.use(vuetify);
  app.use(pina);
  app.use(router);
  app.use(axiosPlugin);
  app.mount('#app');
