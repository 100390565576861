<template>
  
  <v-card max-width="1200" class="mx-auto elevation-1" min-height="100vh">
      <v-layout>                
        <AppBar />
        <v-main>


          <v-container class="py-12">
    <!-- Hero Banner -->
    <v-sheet class="hero-banner rounded-xl d-flex align-center justify-between pa-8 mb-10">
      <div>
        <h1 class="text-h3 font-weight-bold mb-2"  style="text-shadow: rgb(51, 51, 51) 0px 1px 10px;">Welcome Back, {{ user.name }}!</h1>
        <p class="text-body-1 mb-4" style="text-shadow: rgb(51, 51, 51) 0px 1px 10px;">
          {{AccountInfo.Message}}
        </p>
        <v-btn color="primary" v-if="AccountInfo.Target != undefined" rounded class="px-6 py-3 text-white font-weight-medium" :to="AccountInfo.Target">
          {{ AccountInfo.Buttontext }}
        </v-btn>
      </div>
      <v-img
        src="https://source.unsplash.com/featured/?technology"
        class="rounded-lg"
        height="200"
        width="300"
        alt="Technology Image"
      ></v-img>
    </v-sheet>

    <!-- Grid Layout for Account Status and News -->
    <v-row dense>
      <!-- Account Status -->
      <v-col cols="12" md="4">
        <v-card class="status-card rounded-lg pa-6 elevation-2 d-flex flex-column justify-center" style="min-height:200px;">
          <div class="d-flex align-center mb-3">
            <v-icon size="36" color="062135">mdi-account-circle-outline</v-icon>
            <h3 class="text-h6 font-weight-bold ml-3">Account Status</h3>
          </div>
          <p class="text-body-2">Renewed: <strong>{{ user.last_payment }}</strong></p>
          
          <v-btn color="green" rounded text class="mt-4 font-weight-bold" to="/billing">Manage Billing</v-btn>
        </v-card>
        <v-card class="tv-card rounded-lg pa-6 elevation-2 d-flex flex-column justify-center mt-2" style="min-height:200px;">          
          <div class="d-flex align-center mb-3">
            <v-icon size="36" color="062135">mdi-remote-tv</v-icon>
            <h3 class="text-h6 font-weight-bold ml-3">Live TV</h3>
          </div>
          <p class="text-body-2">We have a live tv player that helps offload servers during high traffic events, but also for those that struggle with Jellyfin App</p>
          
          <v-btn color="teal" rounded text class="mt-4 font-weight-bold" to="/live_tv">Watch Now</v-btn>
        </v-card>
      </v-col>

      <!-- Support Section -->
      <v-col cols="12" md="8">
        <v-card class="support-card rounded-lg elevation-2" style="height:100%;">
          
          <div class="blur">
            <div class="d-flex align-center mb-3">
            <v-icon size="36" color="062135">mdi-chat-question</v-icon>
            <h3 class="text-h6 font-weight-bold ml-3">Support</h3>
          </div>
        
        <h3>Need help?</h3>
        <p class="text-body-1 mb-4">
          Join us at Discord, here you can ask for help, chat with others. Hope to see you there!         
        </p>
        <v-btn color="primary" text class="font-weight-bold" @click="Launch('https://discord.gg/bYdN8Xumeu')">Join Discord</v-btn>
      </div>
        </v-card>
      </v-col>
    </v-row>
    

    <v-row dense>
      <!-- News Section -->
      <v-col cols="12" md="12">
        <v-card class="news-card rounded-lg pa-6 elevation-2">
          <div class="d-flex align-center mb-3">
            <v-icon size="36" color="blue">mdi-newspaper-variant-outline</v-icon>
            <h3 class="text-h6 font-weight-bold ml-3">Latest News</h3>
          </div>
          <v-row dense>
              <v-col
                v-for="(item) in this.News" :key="item.id"                
                cols="12"
              >
                <v-card
                  :title="`${item.title}`"
                  :subtitle="`Posted: ${item.news_date} - Admin`"
                  :text="`${item.text}`"
                ></v-card>
              </v-col>
            </v-row>          
        </v-card>
      </v-col>
    </v-row>

  </v-container>



        </v-main>
      </v-layout>
    </v-card>
  </template>


<style scoped>
.hero-banner {
  background: linear-gradient(to right, #4a90e2, #9013fe);
  color: white;
  text-shadow: rgb(51, 51, 51) 0px 1px 10px;
}

.status-card {
  
  background: linear-gradient(.35turn, #7F89D8, #45AEBA);
  color: #ffffff;
  text-shadow: rgb(51, 51, 51) 0px 1px 10px;
}
.blur{
  
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(6px); 
  height: 100%;
	width: 100%;
  padding: 30px;
  
}
.support-card {
  background: linear-gradient(to right, #0f0c0c, #260e3a);
    /* Center and scale the image nicely */
    background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../assets/imgs/banner_discord.avif');
  color: #ffffff;
  font-size: 20px;
  text-shadow: rgb(51, 51, 51) 0px 1px 10px;
        

}
.tv-card{
  background: linear-gradient(.45turn, #45AEBA, #B44AAA);
  color: #ffffff;
  text-shadow: rgb(51, 51, 51) 0px 1px 10px;
}
.news-card {
  background: #ffffff;
  
}

.updates-card {
  background: #ffffff;
  

}

.v-btn {
  text-transform: none;
  font-size: 16px;
}

.v-card:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
}
</style>




<script>
import { useUserStore } from '../stores/user'
import AppBar from '../components/AppBar.vue'


export default {
  data: () => ({
      News: [],
      AccountInfo: {
                Message: "",
                Buttontext: "",
                Target: undefined,
      }      
    }),
  setup() {
    const user = useUserStore();        
    return{user}
  },  
  computed:{
    isExpired: function()
    {
      return Date.parse(this.user.expiry_date)-Date.parse(new Date())<0 ? true: false;
    },
    expiryDate: function()
    {
      return this.user.expiry_date; 
    }

  },
  components: {
    AppBar
    },
    mounted(){
      this.GetNews();
      if(this.isExpired)
        this.AccountInfo =  { Message: "Your account needs some attention!",
                              Buttontext: "Go to Billing",
                              Target: "/billing" 
                            };
      else
        this.AccountInfo =  { Message: "Your account is in good standing. Go to servers to find out more",
                              Buttontext: "Go To Servers",
                              Target: "/server" 
                            };
    },
    methods:{
    Launch(URL){
      window.open(URL,'_blank');                    
    },
    GetNews()
    {
      let self = this;
      self.doneRestorePwd = false;
      const { id, token, email} = this.user; // Extract only needed fields
      



      let NewsData = {
        action:"GET",
        user: { id, email, token },       
            }    
             // Use Axios promise-based syntax
      this.$axios
        .post('/mobilevids/news.php', NewsData)
        .then((response) => {
          this.$nextTick(() => {         
                
                this.News = response.data.data;                        
              });
        })
        .catch((error) => {
          // Handle errors
          if (error.response) {
            this.error = `Error ${error.response.status}: ${error.response.data.message}`;
          } else {
            this.error = error.message;
          }
        })
        .finally(() => {
          // Always executed after success or error
          
        });
            
    },
  }

}
</script>