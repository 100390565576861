<template>
    <v-card max-width="1200" class="mx-auto "  >     

      <v-layout>                
        <AppBar_Admin />
        <v-main>
          <v-container fluid>          
            <v-data-table
                :search="search"
                v-model:expanded="expanded"
                :headers="this.ordersHeaders"
                :items="this.orders"
                item-value="id"
                single-expand
                show-expand
                @update:expanded="loadDetails"
            >
            
                <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>orders</v-toolbar-title>
                    <v-text-field
                    v-model="search"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                    ></v-text-field>
                </v-toolbar>
                
                </template>
                <template v-slot:expanded-row="{ columns, item }">
                <tr>
                    <td :colspan="columns.length">
                    <v-card color="surface-variant" class="mt-5 mb-5">
                        <template v-slot:title>
                          Order Id: {{ item.id }}                  
                        </template>

                        <template v-slot:subtitle>
                         Reason                                                        
                        </template>

                        <template v-slot:text>
                          <v-card class="pa-3">
                            <v-card-title>Result:</v-card-title>
                            <v-card-text>
                              <pre style="white-space: pre-wrap; word-wrap: break-word;">
                                <div v-html="styledJson(item.reason)"></div>
                              </pre>
                            </v-card-text>
                          </v-card>
                        </template>
                        <template v-slot:actions>                                                 
                        </template>
                    </v-card>                  
                    </td>
                </tr>
                </template>
            </v-data-table>   

            
          </v-container>
        </v-main>
      </v-layout>
    </v-card>
  </template>
<style>
.json-key {
  color: #d9534f; /* Red for keys */
  font-weight: bold;
}
.json-value {
  color: #202020; /* Blue for values */
}
</style>
<script>
import { useUserStore } from '../../stores/user'
import AppBar_Admin from '../../components/AppBar_Admin.vue'
import axios from "axios";


export default {
  data: () => ({
      visible: false,
      dialog: false,
      dialog_server: false,
      sel_transaction: null,
      search: '',
      expanded: [],
      orders: [],
      ordersHeaders: [
              
          { title: 'User Id	', key: 'user_id' },              
          {title: 'User', key: 'mail'},
          { title: 'Status', key: 'status' },
          { title: 'Order Id', key: "order_id"},          
          
          {title: 'Updated At', key: 'last_change'},
                    
          { title: '', key: 'data-table-expand' },
        ],
 
    }),
  setup() {
    const user = useUserStore();        
    
    return{user}

  },  
  computed: {
  
  },
  components: {
    AppBar_Admin
    },
    mounted(){
        this.getorders();
 
    },
    methods:{
      styledJson(reason) {
      try {
        // Parse JSON if it's a string
        const parsed = typeof reason === "string" ? JSON.parse(reason) : reason;

        // Convert JSON to formatted and styled HTML
        return this.formatJson(parsed);
      } catch (e) {
        // If parsing fails, return the original reason
        return `<span>${reason}</span>`;
      }
    },
    formatJson(json, indent = 0) {
      if (typeof json !== "object" || json === null) {
        // Base case: return styled value
        return `<span class="json-value">${JSON.stringify(json)}</span>`;
      }

      // If it's an object or array, format recursively
      const isArray = Array.isArray(json);
      const opening = isArray ? "[" : "{";
      const closing = isArray ? "]" : "}";
      const entries = isArray
        ? json.map(value => this.formatJson(value, indent + 2))
        : Object.entries(json).map(
            ([key, value]) =>
              `<span class="json-key">"${key}"</span>: ${this.formatJson(value, indent + 2)}`
          );

      const formattedEntries = entries.join(",<br>" + " ".repeat(indent + 2));
      return `${opening}<br>${" ".repeat(indent + 2)}${formattedEntries}<br>${" ".repeat(indent)}${closing}`;
    },
    FormatDateFromISOString(isoString) {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
  
    loadDetails(item)
    {      

      console.log(item);
      this.expanded.forEach((ex) => {
        if(ex == item)
        {
          this.orders.forEach((u) => {
          if(u.id == item)
          {
            this.sel_transaction = u;
                 
          }
        });
        }
      });
      
      
    },        
    Launch(URL){
      window.open(URL,'_blank');                    
    },
    getorders()
    {
      const { id, token, email} = this.user; // Extract only needed fields    
      let self = this;
      let data = {user: { id, email, token },  }
        axios.post("https://mediatrunk.net/mobilevids/admin/orders.php",data)
        .then(response => {
            self.orders = response.data.orders;     
            
            
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });
    },
  }

}
</script>